import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { Hooks, register } from 'register-service-worker';
import {BehaviorSubject, fromEvent} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  private _deferredPrompt = null;
  private _isEnabled = null;
  public isEnabled$ = new BehaviorSubject(false);

  constructor(
    private _platform: PlatformService
  ) {}

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  public get deferredPrompt() {
    return this._deferredPrompt;
  }

  public initPwa() {
    this._registerServiceWorker();
    this._beforeInstallPrompt();
  }

  private _beforeInstallPrompt() {
    if (this._platform.isBrowser) {
      fromEvent(window, 'beforeinstallprompt').subscribe((e: any) => {
        e.preventDefault();
        this._deferredPrompt = e;
        this._isEnabled = true;
        this.isEnabled$.next(this._isEnabled);
      });
    }
  }

  /**
   * Install PWA app
   */
  public addAppToHomeScreen() {
    this._deferredPrompt.prompt();
    this._deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this._isEnabled = false;
        this.isEnabled$.next(this._isEnabled);
      } else {
        console.log('User dismissed the A2HS prompt');
        this._isEnabled = true;
        this.isEnabled$.next(this._isEnabled);
      }
    });
  }

  /**
   * Register Service Worker
   */
   private _registerServiceWorker(): void {
    if (!this._platform.isBrowser) { return; }
    const serviceWorkerPath = `/sw.js`;
    const options: Hooks = {
      registrationOptions: { scope: './' },
      ready: (registration) => {
        console.log('Service worker is active.');
      },
      registered: (registration) => {
      },
      cached: (registration) => {
      },
      updatefound: (registration) => {
      },
      updated: (registration) => {
      },
      offline: () => {
        console.error('No internet connection found. App is running in offline mode.');
      },
      error: (error) => {
        console.error('Error during service worker registration:', error);
      }
    };
    register(serviceWorkerPath, options);
  }

}
